import {
  sendGA4SearchClickEvent,
  sendGA4SearchResultsClickedEvent,
  sendGA4SearchSelectItemEvent,
} from "@shared/Analytics/content/search-analytics";

const FORM_SELECTOR = ".js-search-form";
const INPUT_SELECTOR = "#search, #ord";
const AUTOCOMPLETE_ITEM_SELECTOR = ".ice-autocomplete-item";

const addAutoCompleteClickEvents = (searchInputs: NodeListOf<Element>) => {
  for (const input of searchInputs) {
    input.addEventListener("Iceac_queryValueChanged", () => {
      handleQueryValueChanged(input);
    });
  }
};

const addSearchSubmitEvents = (forms: NodeListOf<HTMLFormElement>) => {
  for (const form of forms) {
    form.addEventListener("submit", () => {
      handleSearchSubmit(form);
    });
  }
};

const handleSearchSubmit = (form: HTMLFormElement) => {
  const term = getSearchTerm(form);
  if (!term) return;
  const predictions = getSearchPredictions(form);
  sendGA4SearchClickEvent(term, predictions);
};

const handleQueryValueChanged = (input: Element) => {
  const form = input.closest<HTMLFormElement>(FORM_SELECTOR);
  if (!form) throw new Error("Could not find search form when handling queryValueChanged");
  handleSearchSubmit(form);
  form.submit();
};

const getSearchTerm = (form: HTMLFormElement) => {
  const searchInput = form.querySelector<HTMLInputElement>(INPUT_SELECTOR);
  if (!searchInput) return null;
  return searchInput.value;
};

const getSearchPredictions = (form: HTMLFormElement) => {
  const predictionElements = form.querySelectorAll<HTMLElement>(AUTOCOMPLETE_ITEM_SELECTOR);
  if (!predictionElements) return [];

  const predictions = Array.from(predictionElements)
    .map((prediction: HTMLElement) => prediction.innerText)
    .filter(Boolean);
  return predictions;
};

const getListOfTitles = (selector: string, maxReturn: number) => {
  const results = [...document.querySelectorAll<HTMLElement>(selector)];
  return results.slice(0, maxReturn - 1).map((el) => el.innerText);
};

const addSearchResultHitClickEvent = () => {
  const hits = document.querySelectorAll<HTMLAnchorElement>(".search-main li.search-hit a.hit-description");
  const searchTermItem = document.querySelector<HTMLInputElement>(".inline-search__form__container__input #ord")?.value;
  const searchTermValue = searchTermItem?.replace(/^"(.*)"$/, "$1") ?? "";
  const mainHeaderResult = getListOfTitles(".search-main .search-hit .search-hit__title", 10);
  const mainMobileResult = getListOfTitles(".search-mobile .search-hit .search-hit__title", 10);

  hits.forEach((hit) => {
    hit.addEventListener("click", () => {
      const title = hit.dataset.title ?? "";
      sendGA4SearchResultsClickedEvent(searchTermValue, title, mainHeaderResult, mainMobileResult, hit.href);
    });
  });
};

const addSearchProductHitClickEvent = () => {
  const hits = document.querySelectorAll<HTMLAnchorElement>(".search-mobile a.search-hit");
  for (const [index, hit] of hits.entries()) {
    hit.addEventListener("click", () => {
      const name = hit.dataset.name ?? "";
      const brand = hit.dataset.brand ?? "";
      const price = Number.parseInt(hit.dataset.price, 10) || undefined;
      const categorySlug = hit.dataset.categorySlug ?? "";
      const categoryCondition = hit.dataset.categoryCondition ?? "";
      sendGA4SearchSelectItemEvent(name, brand, price, index, categorySlug, categoryCondition);
    });
  }
};

export const bootstrapSearchEvents = (doc: Document | ShadowRoot = document) => {
  const searchInputs = doc.querySelectorAll(INPUT_SELECTOR);
  addAutoCompleteClickEvents(searchInputs);

  const searchForm = doc.querySelectorAll<HTMLFormElement>(FORM_SELECTOR);
  addSearchSubmitEvents(searchForm);

  addSearchResultHitClickEvent();
  addSearchProductHitClickEvent();
};
