import { getBusinessUnit } from "@shared/Utils/analytics-utils";
import { pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import { BusinessUnit, capitalizeFirstLetter } from "@shared/Utils";
import {
  GA4HardwareProduct,
  GA4HardwareSelectItemEvent,
} from "@shared/Analytics/ecommerce/voice/voice-analytics-types";
import { toEquipmentType, toHardwareType } from "@shared/Analytics/ecommerce/voice/Voice-analytics-map";

export type GA4SearchClickEvent = {
  event: "search_click";
  search_term: string;
  search_has_predictions: boolean;
  search_predictions: string; // "no predictions" if no predictions
  business_unit: BusinessUnit;
};

export const sendGA4SearchClickEvent = (term: string, predictions: Array<string>) => {
  const event: GA4SearchClickEvent = {
    event: "search_click",
    search_term: term,
    search_has_predictions: predictions.length > 0,
    search_predictions: predictions.length > 0 ? predictions.join(", ") : "no predictions",
    business_unit: getBusinessUnit(),
  };
  pushToDataLayer(event);
};

export type GA4SearchResultsClickEvent = {
  event: "search_results_clicked";
  search_term: string;
  search_has_results: boolean;
  search_headers: string;
  search_has_items: boolean;
  items_list: string;
  search_header_clicked: string;
  element_url: string;
  business_unit: BusinessUnit;
};

export const sendGA4SearchResultsClickedEvent = (
  term: string,
  clickedHeader: string,
  searchHeaders: string[],
  itemsList: string[],
  url: string,
) => {
  const event: GA4SearchResultsClickEvent = {
    event: "search_results_clicked",
    search_term: term,
    search_has_results: searchHeaders.length > 0,
    search_headers: searchHeaders.join(", "),
    search_has_items: itemsList.length > 0,
    items_list: itemsList.join(", "),
    element_url: url,
    search_header_clicked: clickedHeader,
    business_unit: getBusinessUnit(),
  };
  pushToDataLayer(event);
};

export const sendGA4SearchSelectItemEvent = (
  name: string,
  brand: string,
  price: number | undefined,
  index: number,
  categorySlug: string,
  categoryCondition: string,
) => {
  const hardwareType = toHardwareType(capitalizeFirstLetter(categorySlug));
  const equipmentType = toEquipmentType(hardwareType);
  const newOrUsed = categoryCondition?.toLocaleLowerCase() === "new" ? "Ny" : "Brukt";

  const ga4HardwareProduct: GA4HardwareProduct = {
    item_name: name,
    affiliation: "nettbutikk.ice.no",
    index: index,
    item_brand: brand,
    item_business_unit: getBusinessUnit(),
    item_category: "Hardware",
    item_category2: equipmentType,
    item_category3: hardwareType,
    item_category4: newOrUsed,
    item_list_name: "Search list - Mobiltelefoner",
    item_product_type: `${equipmentType} ${newOrUsed}`,
    price: price,
    quantity: 1,
    item_gtin: null,
  };

  const event: GA4HardwareSelectItemEvent = {
    event: "select_item",
    ecommerce: {
      item_list_name: ga4HardwareProduct.item_list_name,
      items: [ga4HardwareProduct],
    },
  };
  pushToDataLayer(event);
};
